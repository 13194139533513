@import "variables.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "core/palette.scss";
@import "app-variables.scss";
@import "mixins.scss";
@import "custom-bootstrap.scss";
@import "core.scss";
@import "plugins.scss";
@import "customizer.scss";
// @import "demo.scss";  // Remove this SCSS from production

body {
    background-color: #fbfbfb;
    font-family: 'Kanit', sans-serif;
}

select,
select:focus,
textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
    outline: none;
    box-shadow:none !important;
    border: 1px solid #A6A9AE !important;
}

button:disabled {
    cursor: not-allowed;
}

.button-view-image {
    color: #ccc;
    cursor: pointer;
    &:hover {
        color: #676767;
    }
}

.button-trash-item {
    color: #ff8282;
    cursor: pointer;
    &:hover {
        color: #ff3b3b;
    }
}

.item-choosed-header {
    margin: 8px 10px 0px 10px;
    display: block;
    font-size: 12px;
    font-weight: 400;
    color: #ff8d60;
}
.item-selected {
    background-color: #f3f3f3;
    padding: 10px;
    cursor: pointer;
    margin-top: 5px;
    position: relative;
}
.item-selected:hover {
    background-color: #e4e4e4;
}

.image {
    display: flex;
}
.image-icon:hover {
    color: #0985ce;
    cursor: pointer;
}
.image-items {
    padding: 4px;
    cursor: pointer;
}
.image-items:hover {
    box-shadow: 0px 0px 9px 0px rgba(0, 154, 255, 0.8);
}

.react-datepicker__input-container {
    input {
        font-size: 14px;
        width: 210px;
        text-align: center;
        border-radius: 4px;
        border: solid 0.5px #A6A9AE;
        padding: 0.375rem 0.75rem;
    }
}

.page-header-left {
    display: flex;
    align-items: center;
    .header-title {
        margin: 0;
        color: #003450;
        border-bottom: 4px solid #004e79;
        padding-bottom: 4px;
        font-size: 1.3em;
        font-weight: 400;
    }
}
